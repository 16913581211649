import axios from 'axios';
import { Data } from 'interfaces';

export const fetchRoomData = async (roomId: string, inviteCode: string, backendEnv: string | null): Promise<Data> => {
    try {
        console.log(inviteCode);
        const response = await axios.post<Data>(`${backendEnv === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/room/get-room-messages-participants`, {
            roundtableId: roomId,
            inviteCode: inviteCode,
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
