import { InviteProvider } from 'context/inviteCodeContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';

function App() {
  return (
    <div className='App'>
      <InviteProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/:inviteCodeParam" element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </InviteProvider>
    </div>
  );
}

export default App;
