import { openAppOrBrowser } from 'Utils';
import ArrowRight from "assets/images/arrow-up-right.svg";
import PrimaryButton from 'components/PrimaryButton';
import { InviteContext } from 'context/inviteCodeContext';
import { NavBarProps } from 'interfaces';
import React, { useContext, useEffect, useState } from 'react';
import appLogo from "../../assets/images/logo.png";

const Navbar: React.FC<NavBarProps> = ({ roomId }) => {
    const [scrolled, setScrolled] = useState(false);
    const { inviteCode } = useContext(InviteContext);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <nav className={`md:sticky top-0 left-0 w-full flex justify-between items-center px-4 py-1 z-20 transition-colors duration-300 ${scrolled ? 'bg-opacity-50 backdrop-blur-lg bg-black' : 'bg-transparent'}`}>
            <div className="flex items-center">
                <img src={appLogo} alt="App Logo" className="h-20 w-20" />
                <span className="text-white text-base font-bold md:text-xl text-left">QLU Roundtables</span>
            </div>
            <div className='md:hidden border border-white rounded-full'>
                <div className="flex items-center mx-4 ">
                    <button className="text-white text-base py-2 md:text-lg mr-2" onClick={() => openAppOrBrowser(roomId, inviteCode)}>
                        Get App
                    </button>
                    <img src={ArrowRight} />
                </div>
            </div>
            <div className='md:block hidden md:flex md:items-center md:mx-4'>
                <div className='mt-4 mb-5 md:mt-5 px-2'>
                    <PrimaryButton onClick={() => { openAppOrBrowser(roomId, inviteCode) }} title="Join Roundtable" />
                </div>
            </div>

        </nav>
    );
}

export default Navbar;


