import { openAppOrBrowser } from 'Utils';
import BottomDialog from 'components/BottomDialog';
import DiscoverSection from 'components/DiscoverSection';
import FooterSection from 'components/FooterSection';
import HeroSection from 'components/HeroSection';
import Navbar from 'components/Navbar';
import ParticipantsSection from 'components/ParticipantsSection';
import PreviewSection from 'components/PreviewSection';
import { InviteContext } from 'context/inviteCodeContext';
import { Data } from 'interfaces';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchRoomData } from 'services/apiService';
import styles from './HomePage.module.css';

const HomePage: React.FC = () => {
    const [data, setData] = useState<Data | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, serError] = useState("");
    const participantsRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const roomId = queryParams.get('invited') || queryParams.get('roomId') || "";
    const backendEnv = queryParams.get('env') || null;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { inviteCodeParam } = useParams();  // Get the roomId from the path
    const { inviteCode, setInviteCode } = useContext(InviteContext);

    useEffect(() => {
        if (inviteCodeParam) {
            setInviteCode(inviteCodeParam);
        }

        const fetchData = async () => {
            try {
                const roomData = await fetchRoomData(roomId, inviteCodeParam || inviteCode, backendEnv);
                console.log(roomData);
                setData(roomData);
            } catch (error: any) {
                serError(error["message"]);
                setData(null);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [inviteCodeParam, roomId, setInviteCode]);

    const scrollToParticipants = () => {
        if (participantsRef.current) {
            participantsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-r-2 border-cyan-300"></div>
            </div>
        );
    }

    if (data == null) {
        return (
            <div className="flex flex-col items-center justify-center h-screen text-center">
                <h1 className="bg-gradient-to-r from-white to-cyan-500 text-transparent bg-clip-text text-7xl font-bold mb-6">404</h1>
                <h1 className="text-4xl font-bold mb-2 text-white"> Room not found!</h1>
                <p className="text-white">This Roundtable may have been deleted by the creator.</p>
                <p className='text-neutral-500'>{error}</p>
            </div>
        );
    }

    const handleMessageClick = () => {
        console.log('Message clicked:');
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    }

    const handleDialogNext = () => {
        setIsDialogOpen(false);
        openAppOrBrowser(roomId, inviteCode);
    }

    return (
        <div className={`${styles['main-content']}`}>
            <Navbar roomId={roomId} />
            <ToastContainer />
            {data && <HeroSection roomId={roomId} introductions={data.introductions} data={data} scrollToParticipants={scrollToParticipants} onMessageBubbleClick={handleMessageClick} />}
            <DiscoverSection roomId={roomId} />
            <div className='md:hidden'>
                {data && <PreviewSection data={data} onMessageBubbleClick={handleMessageClick} />}
            </div>
            <FooterSection name={data?.roundtable.name ?? ""} />
            <div ref={participantsRef}>
                {data && <ParticipantsSection participants={data.roundtable.RoundtableUsers} introductions={data.introductions} />}
            </div>
            <BottomDialog isOpen={isDialogOpen} onNext={handleDialogNext} onClose={handleDialogClose} />
        </div>
    );
}

export default HomePage;
