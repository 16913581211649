import AppStoreBtn from "assets/images/app-store-btn.svg";
import GoogleStoreBtn from "assets/images/google-store-btn.svg";
import PhonePreview from "assets/images/phones.svg";
import { FooterSectionProps } from "interfaces";

const FooterSection: React.FC<FooterSectionProps> = ({ name }) => {
    return (
        <div className='my-6 md:hidden'>
            <p className='text-white font-bold text-2xl px-10 break-all'>Exclusive Look into "{name}"</p>
            <img src={PhonePreview} alt="App Logo" className="m-auto my-8" />
            <p className='text-white mb-10 font-bold text-3xl px-10'>Available on iPhone & Android</p>
            <div className="flex flex-row justify-center px-10 gap-2">
                <img src={AppStoreBtn} alt="App Store Logo" className="w-32" onClick={() => {
                    window.location.href = process.env.REACT_APP_STORE_URL ?? "";
                }} />
                <img src={GoogleStoreBtn} alt="Google Play Logo" className="w-36" onClick={() => {
                    window.location.href = process.env.REACT_APP_PLAY_STORE_URL ?? "";
                }} />
            </div>
        </div>
    );
}

export default FooterSection;
