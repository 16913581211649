import React from 'react';
import styles from './PrimaryButton.module.css';

type PrimaryButtonProps = {
    onClick: () => void;
    title: string;
};

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ onClick, title }) => {
    return (
        <button
            // className={`${styles['primary-button']} relative`}
            className={`${styles['primary-button']} relative text-black uppercase font-bold tracking-widest`}
            onClick={onClick}
        >
            {title}
        </button>
    );
};

export default PrimaryButton;
