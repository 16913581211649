import { Introduction, RoundtableUser } from "interfaces";

export const openAppOrBrowser = async (roomId: string, roomCode: string) => {
    // const deeplinkUrl = `${process.env.REACT_APP_SCHEMA}invited=${roomId}`;
    console.log(roomCode);
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (roomId) {
        await navigator.clipboard
            .writeText(`invited:${roomId}`)
    } else if (roomCode) {
        await navigator.clipboard
            .writeText(`inviteCode:${roomCode}`)
    }


    if (isAndroid) {
        window.location.href = process.env.REACT_APP_PLAY_STORE_URL ?? "";
    } else {
        window.location.href = process.env.REACT_APP_STORE_URL ?? "";
    }
}

export const getUserMediaInfo = (user: RoundtableUser, introductions: Introduction[], playingStates: {
    [key: string]: boolean;
}) => {
    const intro = user.UserRoundtableStatuses?.introId
        ? introductions.find(intro => intro.introId === user.UserRoundtableStatuses.introId)
        : null;

    const isUserPlaying = playingStates[intro?.introId || ''] ?? false;
    const isAudio = intro && intro.type === 'audio' ? true : false;

    return { intro, isUserPlaying, isAudio };
};